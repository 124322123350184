<template>
    <div class="form-group row" id="course-frequency-container">
        <div class="add-new-course-date-row full-width d-md-flex" v-for="(course, index) in courses" :key="course.id">
            <div class="col-md-3 col-lg-2">
                <select id="day" :name="'dates['+index+'][day]'" class="form-control mt-2" required>
                    <option value="">{{texts.placeholders.day}}</option>
                    <option v-for="(key_name, value) in days" :value="value" :selected="value == course.day">{{daysTexts[key_name]}}</option>
                </select>
            </div>
            <div class="col-md-3 col-lg-2">
                <input id="start_time" :name="'dates['+index+'][start_time]'" type="text" onfocus="(this.type='time')" onblur="(this.type='text')" :placeholder="[[texts.placeholders.start_time]]" class="form-control mt-2" @change="editStartTime(index, $event.target.value)" :value="course.start_time" required autocomplete="start_time">
            </div>
            <div class="col-md-3 col-lg-2">
                <input id="end_time" :name="'dates['+index+'][end_time]'" type="text" onfocus="(this.type='time')" onblur="(this.type='text')" :placeholder="[[texts.placeholders.end_time]]" class="form-control mt-2" @change="editEndTime(index, $event.target.value)" :value="course.end_time" required autocomplete="end_time">
            </div>
            <div class="col-md-3 col-lg-2" v-if="index == courses.length-1">
                <button type="button" @click="addCourse" class="btn btn-transparent mt-2"><i class="fa fa-plus-circle"></i>&nbsp;{{texts.btnAddDay}}</button>
            </div>
            <div class="col-md-3 col-lg-2" v-else>
                <button type="button" @click="removeCourse(index)" class="btn btn-transparent mt-2"><i class="fa fa-times-circle" style="color: red;"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                courses: (this.dates && this.dates.length > 0) ? this.dates : [{'id':1, 'day': null, 'start_time': null, 'end_time': null}],
            }
        },
        props: {
            texts: { required: true },
            days: { required: true },
            daysTexts: { required: true },
            dates: {}
        },
        methods: {
            addCourse: function() {
                var lastID = this.courses[this.courses.length - 1].id;
                this.courses.push({'id':lastID+1, 'day': null, 'start_time': null, 'end_time': null});
            },
            removeCourse: function(index) {
                this.courses.splice(index, 1);
            },
            editStartTime: function(index, val) {
                this.courses[index].start_time = val
            },
            editEndTime: function(index, val) {
                this.courses[index].end_time = val
            }
        }
    }
</script>