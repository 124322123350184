$(document).ready(function(){

    //Aggiunta azione per rimuovere i figli
    $( ".remove-child" ).each(function( index ) {
        $(this).on("click", removeChild)
    });

    //Azione per invio su input nome figlio
    $("#child-name").keyup(function(event) {
        if (event.keyCode === 13) {
            $("#add_child").click();
        }
    });

    //Aggiunta azione sul tasto aggiunti figlio
    $('#add_child').on("click", addChild)

});


function removeChild() {
    $.ajax({
        url: $(this).data('url'),
        dataType: 'json',
        type: 'post',
        contentType: 'application/json',
        data: JSON.stringify({ '_token': $(this).parent().data('token'), 'id' : $(this).parent().data('pk') }),
        processData: false,
        success: function( data, textStatus, jQxhr ){
            location.reload();
        },
    });
}


function addChild() {

    if ($('#child-name').val() == "") {console.log("return"); return;}

    $.ajax({
        url: $(this).data('url'),
        dataType: 'json',
        type: 'post',
        contentType: 'application/json',
        data: JSON.stringify({ '_token': $(this).data('token'), 'name' : $('#child-name').val() }),
        processData: false,
        success: function( data, textStatus, jQxhr ){
            location.reload();
        },
    });

    $('#child-name').val('');
}
