$(document).ready(function(){
    $('.data-table').DataTable({
        "aaSorting": [],
        "language": {
            "url": "/api/lang/pagination/datatable"
        },
        "pageLength": $(this).data('page-length'),
        "responsive": true,
        "columnDefs":  [
            {
                "targets": 'no-sort',
                "orderable": false,
                "width" : "5%"
            },
            { responsivePriority: 2, targets: -1}
        ]

    });
});
