import * as Funzioni from './functions';

$(document).ready(function(){
    Funzioni.init();

    $('.hamburger').on('click', function () {
        //Evento che al click aggiunge la classe active al menu Hamburger
        var $this = $(this);
        $('body').toggleClass('menu-open');
        $(this).toggleClass('is-active');
        $('.menu-list').toggleClass('close-menu');

    });

    $('.menu-link a, .menu-link-left a').on('click', function () {
        var urlLink = $(this).attr("href");
        //var anchor = urlLink.substr(0, urlLink.indexOf('#'));
        var anchor = urlLink.substr(urlLink.indexOf('#'));

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, function () {
                $('.menu-list').addClass('close-menu');
            }
        );
    });

});