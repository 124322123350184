import { Calendar } from '@fullcalendar/core';
import itLocale from '@fullcalendar/core/locales/it'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Tooltip from 'tooltip.js';

export function init() {
    hideBanner();
    loadUsersListDatatable();
    showModalUserActive();
    setUserActive();
    followClub();
    medicalCertificateForm();
    showInsertBox();
    showModalDeleteCertificate();
    deleteCertificate();
    loadCourseHoursSlider();
    selectUserCourse();
    insertUserCourse();
    rejectCourseRequest();
    handlerPaymentModal();
    loadMultiselect();
    loadSelectPicker();
    handleComunicationsRecipients();
    loadQuillTextEditor();
    sendComunication();
    showModalDeleteGroup();
    deleteGroup();
    showModalDeleteComunication();
    deleteComunication();
    loadCalendar();
}

/**
 * nascondiamo un eventuale banner di notifica
 */
function hideBanner() {
    $(".flash-message").delay(3500).fadeOut();
}

/**
 * Carica la datatable sulla pagina users_list
 */
function loadUsersListDatatable() {
    var table = $("#users_table").DataTable({
        "aaSorting": [],
        "language":{
            "url": "/api/lang/pagination/datatable"
        },
        "responsive": true,
        "columns": [
            null,
            null,
            null,
            { "width": "75px" },
            { "width": "50px", "orderable": false }
        ]
    });

    $("#users_table tfoot th").each( function (i) {
        // attiviamo la dropdown con il filtro solo per la colonna al terzo posto (gruppo)
        if (i === 2) {
            var select = $('<select><option value=""></option></select>')
                .appendTo( $(this).empty() )
                .on( 'change', function () {
                    var val = $(this).val();

                    table.column( i )
                        .search( val ? '^'+$(this).val()+'$' : val, true, false )
                        .draw();
                } );

            table.column(i).data().unique().sort().each( function ( d, j ) {
                select.append( "<option value="+d+">"+d+"</option>" );
            } );
        }
    } );
}

/**
 * Mostriamo il popup di attivazione/disattivazione utente nella pagina della lista utenti
 */
function showModalUserActive() {
    var myModal = $('#changeUserActiveModal');
    myModal.modal({ show: false});

    $('.toggleUserActive').click(function () {
        var userId = $(this).attr('data-user-id');
        var url = $(this).attr('data-url');
        $("#changeUserActiveModal .modal-body #userId").val(userId);
        $("#changeUserActiveModal .modal-body #url").val(url);
        myModal.modal('show');
    });
}

/**
 * L'admin può attivare o disattivare utenti solo premendo su "continua" nella modal della pagina users_list
 */
function setUserActive() {
    $('#continueModalUserActive').click(function () {
        var userId = $("#changeUserActiveModal .modal-body #userId").val();
        var url = $("#changeUserActiveModal .modal-body #url").val();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'PUT',
            url: url,
            data:  { userId: userId},
            dataType: 'json',
            success: function(result) {
                if (result['userActive'] == 1) {
                    $("#user_"+userId+" .toggleUserActive").removeClass('user_not_active').addClass('user_active').html(result['text']);
                } else {
                    $("#user_"+userId+" .toggleUserActive").removeClass('user_active').addClass('user_not_active').html(result['text'])
                }
            }
        });

        $("#changeUserActiveModal").modal('hide');
    });
}

/**
 * L'admin può attivare o disattivare utenti solo premendo su "continua" nella modal della pagina users_list
 */
function followClub() {
    $('.follow-club .switch-input').change(function () {
        var clubId = $(this).attr('data-club-id');
        var url = $(this).attr('data-url');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'PUT',
            url: url,
            data:  { clubId: clubId},
            dataType: 'json',
            success: function(result) {

            }
        });
    });
}

/**
 * animazione comparsa/scomparsa box inserimento certificato medico e inserimento nuovo gruppo
 */
function showInsertBox(){
    var box = $(".insert-box");
    $("#showInsertBox").click(function (){
        if (box.is(":visible")) {
            box.slideUp();
        } else {
            box.slideDown();
        }
    });
}

/**
 * mostriamo il popup di conferma per cancellare un certificato
 */
function showModalDeleteCertificate() {
    var myModal = $('#deleteMedicalCertificateModal');
    myModal.modal({ show: false});

    $('.delete_certificate').click(function () {
        var id = $(this).attr('data-id');
        var url = $(this).attr('data-url');
        $("#deleteMedicalCertificateModal .modal-body #id").val(id);
        $("#deleteMedicalCertificateModal .modal-body #url").val(url);
        myModal.modal('show');
    });
}

/**
 * cancelliamo il certificato
 */
function deleteCertificate() {
    $("#continueModalDeleteCertificate").click(function() {
        var id = $("#deleteMedicalCertificateModal .modal-body #id").val();
        var url = $("#deleteMedicalCertificateModal .modal-body #url").val();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'DELETE',
            url: url,
            data:  {id: id},
            success: function(result) {
                location.reload();
            }
        });
    });
}

/**
 * intercettiamo il submit del form così possiamo scatenare noi l'evento per il caricamento dei file nella dropzone
 */
function medicalCertificateForm() {
    loadDropzoneMedicalCertificates();
    var form = $("form[name=addMedicalCertificate]");
    var action = form.attr('data-action');
    var method = form.attr('data-method');

    if (document.getElementById('medical-certificates-upload')){
        var dropzone = Dropzone.forElement(".dropzone");
    }
    var dropzoneForm = $("#medical-certificates-upload");

    $("#insertMedicalCertificate").click(function (e) {
        e.preventDefault();
        if (dropzone.getQueuedFiles().length > 0) {
            form.submit();
        } else {
            $("#attachment.error-message").removeClass("d-none").delay(3500).queue(function(next){
                $(this).addClass("d-none");
                next();
            });
        }
    });

    $(form).submit(function (e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: method,
            url: action,
            data:  form.serializeArray(),
            dataType: 'json',
            success: function(result) {
                dropzone.options.url = dropzoneForm.attr('data-action').replace('%id_certificate%', result);
                dropzone.processQueue();
            },
            error: function(xhr, status, error) {
                var json = JSON.parse(xhr.responseText);
                $.each(json, function(key, err){
                    $("input[name="+key+"], select[name="+key+"]").parent().after("<div class='error-message'>"+err+"</div>").delay(3500).queue(function(next){
                        $(".error-message").remove();
                        next();
                    });
                });
            }
        });
    });

}

/**
 * Carichiamo la dropzone per il caricamento dei certificati medici
 */
function loadDropzoneMedicalCertificates() {
    var dropzone = $("#medical-certificates-upload");
    dropzone.dropzone({
        url: dropzone.attr('data-action'),
        autoProcessQueue: false,
        maxFilesize: 10,
        maxFiles: 1,
        acceptedFiles: 'image/*, application/pdf',
        maxfilesexceeded: function(file) {
            this.removeAllFiles();
            this.addFile(file);
        },
        renameFile: function(file) {
            var dt = new Date();
            var time = dt.getTime();
            var filename = file.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '').replace(' ', '_').toLowerCase();
            return time+'_'+filename;
        },
        sending: function(file, xhr, formData) {
            formData.append("_token", $('[name=_token]').val());
        },
        success: function() {
            window.location.reload()
        },
        error: function (file, response) {
            return false;
        }
    });
}

/**
 * Carichiamo lo slider nella pagina di iscrizione ad un circolo e nella pagina di inserimento corso lato circolo
 */
function loadCourseHoursSlider() {
    // $("#hours-range-slider").ionRangeSlider({
    //     type: "double",
    //     min: moment("09:00", "HH:mm").format('x'),
    //     max: moment("21:00", "HH:mm").format('x'),
    //     grid: true,
    //     grid_snap: true,
    //     step: 1800000, // mezz'ora
    //     prettify: function (num) {
    //         return moment(num, 'x').format("HH:mm");
    //     },
    //     onStart: function (data) {
    //         $("#this_start_time").val(moment(data.from, 'x').format("HH:mm:ss"));
    //         $("#this_end_time").val(moment(data.to, 'x').format("HH:mm:ss"));
    //     },
    //     onFinish: function (data) {
    //         $("#this_start_time").val(moment(data.from, 'x').format("HH:mm:ss"));
    //         $("#this_end_time").val(moment(data.to, 'x').format("HH:mm:ss"));
    //     }
    // });
}

/**
 * Gestiamo il comportamento del click su un corso nella relativa modal di selezione
 */
function selectUserCourse() {
    $(".select-course").on("click", function() {

        $(".select-course").each(function() {
            $(this).removeClass('selected');
            $(this).children('i').addClass('d-none');
        });

        $(this).addClass('selected');
        $(this).children('i').removeClass('d-none');
        $("#courseSelectionModal form input[name=course_id]").val($(this).data('id'));
    });
}

/**
 * Quando preme OK nella modal di aggiunta utente a corso, controlliamo ci sia un corso selezionato e inviamo il form
 */
function insertUserCourse() {
    $("#continueModalAddUserCourse").click(function(){
        if (!$("input[name=course_id]").val() == ""){
            $("form[name=userCourse]").submit();
        }
    });
}

/**
 * Inviamo il form per rifiutare la richiesta di iscrizione al corso
 */
function rejectCourseRequest() {
    $("#continueModalRejectUserCourse").click(function() {
        $("form[name=rejectRequest]").submit();
    });
}

/**
 * Gestiamo le iterazioni sulla modal dei pagamenti
 * Al click sulla freccettina nella lista dei pagamenti mostriamo la modal con titolo dinamico
 * Al cambiamento del radio button cambiamo il testo in basso
 */
function handlerPaymentModal() {
    var myModal = $('#paymentModal');
    myModal.modal({ show: false});

    var fullCost;
    var hasInstallments;

    $('.show-payment-modal').click(function (){
        fullCost = $(this).data("full-cost");
        var modalTitle = $("#paymentModalTitle");

        $("#solution1").trigger('click');
        $("input[name=payment_id]").val($(this).data('id'));

        // se abbiamo una rata vuol dire che è un pagamento figlio di un altro pagamento, quindi dobbiamo disabilitare la possibilità di modificare il numero di rate
        // il metodo di pagamento rimane modificabile perchè inerente al pagamento singolo
        hasInstallments = $.isNumeric($(this).data('installment'));
        if (hasInstallments) {
            $("#installments").remove();
            $("form[name=payment]").removeClass('mb-4');
        }

        $(".modal-header").addClass('align-items-center');
        modalTitle.html($(this).data("course-title")).next('.close').remove();
        modalTitle.next('.modal-title').remove();
        modalTitle.after('<div class="modal-title">'+fullCost+'</div>');
        myModal.modal('show');
    });

    $("#method-show-info").click(function () {
       $("#under-info-methods").removeClass('d-none');
    });

    $('input[name=installments]').change(function (){
        $("#choosenInstallment").html($(this).next('label').text());
        var selectedNumber = $(this).val();

        var cost = parseFloat(fullCost.substr(2, fullCost.length).replace(',','.'))/parseFloat(selectedNumber);
        cost = cost.toFixed(2);

        $("#cost").html(cost.toString());

        $(".choosed-installment").each(function (){
            ($(this).data('id') == selectedNumber) ? $(this).removeClass('d-none') : $(this).addClass('d-none');
        });

        activateButton();
    });

    $(".choosable-method").click(function () {
        var _this = $(this);
        var selectedMethod = _this.data('method');
        $("input[name=method]").val(_this.data('value')).trigger('change');

        $(".choosable-method").removeClass('selected');
        _this.addClass('selected');

        $(".choosed-method").each(function (){
            ($(this).data('method') == selectedMethod) ? $(this).removeClass('d-none') : $(this).addClass('d-none');
        });
    });

    $("input[name=method]").change(function (){
        activateButton();
    });

    function activateButton() {
        if ($("input[name=method]").val() != "" && ($("input[name=installments]").is(':checked') || hasInstallments)) {
            $("#modalPayButton").removeAttr('disabled');
        }
    }

    $("#modalPayButton").click(function (){
       $("form[name=payment]").submit();
    });
}

/**
 * carichiamo il dual listbox multiselect
 */
function loadMultiselect() {
    var multiselect = $(".multiselect");
    var placeholder = multiselect.data("placeholder");
    multiselect.multiSelect({
        selectableHeader: "<input type='text' class='search-input' autocomplete='off' placeholder='"+placeholder+"'>",
        selectionHeader: "<input type='text' class='search-input' autocomplete='off' placeholder='"+placeholder+"'>",
        afterInit: function(ms){
            var that = this,
                $selectableSearch = that.$selectableUl.prev(),
                $selectionSearch = that.$selectionUl.prev(),
                selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)',
                selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';

            that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
                .on('keydown', function(e){
                    if (e.which === 40){
                        that.$selectableUl.focus();
                        return false;
                    }
                });

            that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
                .on('keydown', function(e){
                    if (e.which === 40){
                        that.$selectionUl.focus();
                        return false;
                    }
                });
        },
        afterSelect: function(){
            this.qs1.cache();
            this.qs2.cache();
        },
        afterDeselect: function(){
            this.qs1.cache();
            this.qs2.cache();
        }
    });
}

/**
 * carichiamo il bootstrap multiselect
 */
function loadSelectPicker() {
    $(".selectpicker").each(function () {
        $(this).selectpicker();
    });
}

/**
 * gestiamo gli elementi nella pagina di invio di una nuova comunicazione
 */
function handleComunicationsRecipients() {
    var selectPickerGroups = $("#selected-groups-select");
    var selectPickerUser = $("#single-user-select");

    var radioAll = $('#all[name=recipients]');
    var radioGroups = $('#groups[name=recipients]');
    var radioSingleUser = $('#singleuser[name=recipients]');

    var preloadedId = $("#preloadedId");

    selectPickerGroups.on('changed.bs.select', function (e) {
        $("#groups-list-container").html('');

        var options = $('#selected-groups-select option:selected');

        $(options).each(function(){

            var template = "" +
                "<div class='selected-group' id='"+$(this).val()+"'>" +
                    "<div class='text'>"+
                        "<span>"+$(this).text()+"</span>"+
                        "<button class='close'><i class='fa fa-times'></i></button>" +
                    "</div>" +
                "</div>";
            $("#groups-list-container").append(template);

        });
    });

    radioGroups.click(function () {
        disableSelect(selectPickerGroups, false);
        disableSelect(selectPickerUser, true);
    });

    radioSingleUser.click(function () {
        disableSelect(selectPickerGroups, true);
        disableSelect(selectPickerUser, false);
    });

    if(preloadedId.length) {
        radioGroups.click();
        selectPickerGroups.find('[value='+preloadedId.val()+']').prop('selected', true);
        disableSelect(selectPickerGroups, false, selectPickerGroups.val());
    }

    if (radioGroups.attr('checked')) {
        disableSelect(selectPickerGroups, false, selectPickerGroups.val());
    }

    radioAll.click(function () {
        disableSelect(selectPickerGroups, true);
        disableSelect(selectPickerUser, true);
    });

    $(document).on('click', '.close', function(){
        var _this = $(this).parent().parent();
        _this.remove();

        selectPickerGroups.find('[value='+_this.attr('id')+']').prop('selected', false);
        var values = selectPickerGroups.val();
        selectPickerGroups.selectpicker('deselectAll');
        selectPickerGroups.selectpicker('val', values );
        selectPickerGroups.selectpicker('refresh');
    });

    function disableSelect(select, disable, withVal = null) {
        select.prop('disabled', disable);
        if (disable) {
            select.selectpicker('deselectAll');
        }
        if (withVal) {
            select.selectpicker('val', withVal);
        }
        select.selectpicker('refresh');
    }


}

/**
 * carichiamo Quill Text Editor sugli elementi
 */
function loadQuillTextEditor() {
    var container = $('.editor').get(0);
    if (container) {
        var editor = new Quill(container, {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    ['link'/*, { 'align': [] }*/],
                    // [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ 'color': [] }, { 'size': ['small', false, 'large'] }, { 'header': [1, 2, 3, 4, false] }]
                ]
            },
            theme: 'snow',
            placeholder: $('.editor').data('placeholder')
        });
        $('.editor').data("quill", editor);
    }
}

/**
 * intercettiamo il form di invio comunicazione perchè dobbiamo recuperare l'html del text editor, salvando quello
 */
function sendComunication() {

    var quill = $('.editor').data("quill");
    var quillDelta = $("input[name=message_quill]").val();
    if (quillDelta && quillDelta !== '') {
        quill.setContents(JSON.parse(quillDelta), 'api');
    }

    var form = $("form[name=sendComunicationForm]");
    form.submit(function (e) {
        e.preventDefault();

        var message = $("input[name=message]");
        message.val(quill.root.innerHTML);

        var quill_message = $("input[name=message_quill]");
        quill_message.val(JSON.stringify(quill.getContents()));

        form.unbind('submit').submit()
    });

}

/**
 * mostriamo il popup di conferma per cancellare un gruppo
 */
function showModalDeleteGroup() {
    var myModal = $('#deleteGroupModal');
    myModal.modal({ show: false});

    $('.delete_group').click(function () {
        var id = $(this).data('id');
        var url = $(this).data('url');
        $("#deleteGroupModal .modal-body #id").val(id);
        $("#deleteGroupModal .modal-body #url").val(url);
        myModal.modal('show');
    });
}

/**
 * cancelliamo il gruppo
 */
function deleteGroup() {
    $("#continueModalDeleteGroup").click(function() {
        var id = $("#deleteGroupModal .modal-body #id").val();
        var url = $("#deleteGroupModal .modal-body #url").val();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'DELETE',
            url: url,
            data:  {id: id},
            success: function(result) {
                location.reload();
            }
        });
    });
}

/**
 * mostriamo il popup di conferma per cancellare una comunicazione
 */
function showModalDeleteComunication() {
    var myModal = $('#deleteComunicationModal');
    myModal.modal({ show: false});

    $('.delete_comunication').click(function () {
        var id = $(this).data('id');
        var url = $(this).data('url');
        $("#deleteComunicationModal .modal-body #id").val(id);
        $("#deleteComunicationModal .modal-body #url").val(url);
        myModal.modal('show');
    });
}

/**
 * cancelliamo la comunicazione
 */
function deleteComunication() {
    $("#continueModalDeleteComunication").click(function() {
        var id = $("#deleteComunicationModal .modal-body #id").val();
        var url = $("#deleteComunicationModal .modal-body #url").val();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'DELETE',
            url: url,
            data:  {id: id},
            success: function(result) {
                location.reload();
            }
        });
    });
}

function loadCalendar() {
    var calendarEl = document.getElementById('calendar');
    if (calendarEl) {
        var calendar = new Calendar(calendarEl, {
            height: 500,
            allDaySlot: false,
            plugins: [ timeGridPlugin, dayGridPlugin ],
            locale: itLocale,
            timeZone: 'Rome',
            defaultView: 'timeGridWeek',
            events: $("#calendar").data('events'),
            header: {
                left:   'prev,next today',
                center: 'title',
                right:  'dayGridMonth, timeGridWeek, timeGridDay'
            },
            titleFormat: { year: 'numeric', month: 'long', day: '2-digit' },
            eventClick: function(info) {
                $("#reportAbsenceModal input[name=id]").val(info.event.id);
                $("#reportAbsenceModal input[name=day]").val(moment(info.event.start).format('DD/MM/YYYY'));
                $("#reportAbsenceModal").modal('show');
            },
            eventRender: function(info) {
                info.el.innerHTML += "<div><i class='fa fa-user-slash'></i></div>";
                $(info.el).tooltip({ title: info.event.extendedProps.description });
            },
        });
        calendar.render();
    }
}